<template>
<div>
  <!-- lists -->
  <template>
    <div :class="'d-flex flex-column ' + (isMobile ? '' : 'sample-container')">
      <title-pond></title-pond>
      <div class="mt-5 mb-0 pb-0">
        <pond-menu></pond-menu>
      </div>
      <v-row class="align-center flex-grow-0">
        <v-col cols="3" class="font-weight-bold">
          <div class="device-sid">Add Device SID : </div>
        </v-col>
        <v-col>
          <v-text-field
            label=""
            v-model="newdevice"
            placeholder=""
            solo
            class="mt-0 text-right input-yellow add-device"
            style="max-width: 300px"
            @input="searchDevice()"
            @keydown.space="(event) => event.preventDefault()"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="newdevice" class="flex-grow-1 flex-shrink-1 mb-3 full-height newdevice-container" justify="center">
        <v-simple-table height="350" fixed-header class="flex-grow-1">
          <template>
            <thead >
              <tr>
                <th class="text-left"></th>
                <th class="border-right">SID</th>
                <th class="">NAME</th>
                <th class="">Pond</th>
                <th class=""></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="loadingStatus==2">
                <tr>
                  <td class="nodata" colspan="7" align="center">
                    <v-progress-circular
                      indeterminate
                      color="amber"
                    ></v-progress-circular>
                  </td>
                </tr>
              </template>
              <template v-else-if="devices_list.find(o=>o.sid==newdevice)">
                <tr>
                  <td class="nodata name-exist" colspan="5" align="center">
                    This device name is in this pond.
                  </td>
                </tr>
              </template><template v-else-if="!newdevices_list.length">
                <tr>
                  <td class="nodata" colspan="5" align="center">
                    No Devices found in other ponds.<br/>Would you like to create a new device?<br/><br/>
                    <v-btn small color="primary" class="mt-" @click="createDevice()">
                      Add Device
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template v-for="(item, i) in newdevices_list">
                <template >
                  <tr
                    :key="item.id"
                  >
                    <td class="border-right">
                      {{i+1}}
                    </td>
                    <td class="border-right font-weight-bold">
                      {{item.sid}}
                    </td>
                    <td class="sid font-weight-bold">
                      {{item.name}}
                    </td>
                    <td class="sid font-weight-bold">
                      {{item.pond_name}}
                    </td>
                    <td class="device-del font-weight-bold">
                      <v-btn small @click="moveHere(item)">
                        Move Here
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
      <v-row class="flex-grow-1 flex-shrink-1 full-height" justify="center">
        <v-simple-table class="mt-0 flex-grow-1">
          <template #default>
            <thead>
              <tr>
                <th class="text-left"></th>
                <th class="border-right">SID</th>
                <th class="">NAME</th>
                <th class=""></th>
              </tr>
            </thead>
            <tbody>
              <template v-if="loadingStatus==1">
                <tr>
                  <td class="nodata" colspan="7" align="center">
                    <v-progress-circular
                      indeterminate
                      color="amber"
                    ></v-progress-circular>
                  </td>
                </tr>
              </template>
              <template v-else-if="!devices_list.length">
                <tr>
                  <td class="nodata" colspan="5" align="center">No Devices</td>
                </tr>
              </template>
              <template v-for="(item, i) in devices_list">
                <template >
                  <tr
                    :key="item.id"
                  >
                    <td class="border-right">
                      {{i+1}}
                    </td>
                    <td class="border-right font-weight-bold">
                      {{item.sid}}
                    </td>
                    <td class="sid font-weight-bold">
                      <v-text-field
                        label=""
                        v-model="item.name"
                        placeholder=""
                        solo
                        class="mt-0 text-right input-yellow devicename"
                        style="max-width: 300px"
                        @change="nameUpdate(item)"
                      >
                      </v-text-field>
                    </td>
                    <td class="device-del font-weight-bold">
                      <v-btn small @click="deleteDevice(item)">
                        <v-icon color="pink" small>mdi-close-circle</v-icon> Delete
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
    </div>
  </template>
</div>
</template>

<script>
import { mapState } from "vuex";

import tokenAxios from "@/components/tokenAxios";
import TitlePond from "@/components/TitlePond";
import PondMenu from './PondMenu'
import Swal from "sweetalert2";

export default {
  name: 'Samples',
  computed: {
    ...mapState(["isMobile", "user_info", "pond", "loadingStatus"]), // get user_info and pond from store
    getPond() {
      if(this.pond) return `${this.pond.farm_name}, Pond ${this.pond.pond_id}, ${this.$options.filters.formatDate(this.pond.date)}`
      else return "";
    }
  },
  components: {
    PondMenu,
    TitlePond,
  },
  data: () => ({
    API_SERVER: process.env.VUE_APP_API_SERVER,
    devices_list: [],
    newdevices_list: [],
    newdevice: "",
  }),
  created() {
    this.$debug("created", this.pond)
    if( !this.pond ) this.$router.push('/ponds')
    this.listDevices();
  },
  methods: {
    // List Samples
    async listDevices(page) {
      try {
          this.$debug( "API_SERVER", this.API_SERVER, "this.pond", this.pond )
          this.$store.commit("loadingStatus", 1);
          const response = await tokenAxios(this.user_info.access_token).get(
            this.API_SERVER + `/farm/devices/?format=json&pond=${this.pond.id}&` + (page ? `&page=${this.page}` : "")
          );
          this.$debug("get devices lists", response);
          if (response.status === 200 && response.data.results) {
            this.devices_list = response.data.results;
            // Update pond devices
            this.$debug('setPond', this.pond)
            this.$store.commit("pond", {...this.pond, device: response.data.results});
          } else {
            this.$debug("error", response);
          }
      } catch (e) {
          this.$debug("error", e);
      } finally {
          this.$store.commit("loadingStatus", 0);
      }
    },

    // Create a device
    async createDevice() {
      try {
        const confirm = await Swal.fire({
          text: `Would you like to create Device [${this.newdevice}]?`,
          confirmButtonText: "Create"
        });

        if( confirm ) {
          this.$debug('create', this.newdevice)
          var formData = new FormData();
          formData.append("pond", this.pond.id);
          formData.append("sid", this.newdevice);
          formData.append("test", "test0002!");
          this.$debug("create", formData)

          await tokenAxios(this.user_info.access_token).post(
            process.env.VUE_APP_API_SERVER + `/farm/devices/`,
            formData
          );

          this.$root.vtoast.show({message: `Device ${this.newdevice} is added`})
          this.newdevice = ''
          this.listDevices()
          // set Submitted
          this.status = 0;
        }
      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      }
    },

    // Delete a device
    async deleteDevice(item) {
      try {
        const confirm = await Swal.fire({
          text: `Would you like to delete Device [${item.sid}]?`,
          confirmButtonText: "Delete"
        });

        if( confirm ) {
          this.$debug('delete', this.newdevice)

          const response = await tokenAxios(this.user_info.access_token).delete(
            process.env.VUE_APP_API_SERVER + `/farm/devices/${item.id}/`,
          );
          if (response.status === 200) {
            this.$root.vtoast.show({message: `Device ${this.newdevice} is deleted`})
            this.newdevice = ''
            this.listDevices()
          }
          // set Submitted
          this.status = 0;
        }
      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      }
    },

    // Add device -> search existing devices
    async searchDevice() {
      console.log('searchDevice', this.newdevice, !this.newdevice)
      if(!this.newdevice) return

      try {
          this.$debug( "API_SERVER", this.API_SERVER, "this.pond", this.pond )
          this.$store.commit("loadingStatus", 2);
          const response = await tokenAxios(this.user_info.access_token).get(
            this.API_SERVER + `/farm/devices/?format=json&sid=${encodeURIComponent(this.newdevice)}&pond=${this.pond.id}`
          );
          this.$debug("get devices lists", response);
          if (response.status === 200) {
            this.newdevices_list = response.data.results;
          } else {
            this.$debug("error", response);
          }
      } catch (e) {
          this.$debug("error", e);
      } finally {
          this.$store.commit("loadingStatus", 0);
      }
    },

    // Move other device to this pond
    async moveHere(item) {
      try {
        const confirm = await Swal.fire({
          text: `Would you like to move [Device ${item.sid}] to this pond?`,
          confirmButtonText: "Move"
        });

        if( confirm ) {
          this.$debug('partialUpdate', item.name)
          var formData = new FormData();
          formData.append("pond", this.pond.id );
          this.$debug("partialUpdate", formData)

          await tokenAxios(this.user_info.access_token).patch(
            process.env.VUE_APP_API_SERVER + `/farm/devices/${item.id}/`,
            formData
          );

          this.$root.vtoast.show({message: `Device ${item.sid} is moved here.`})
          this.newdevice = ''
          this.listDevices()
          // set Submitted
          this.status = 0;
        }
      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      }
    },

    // Update a device name
    async nameUpdate(item) {
      try {
        const confirm = await Swal.fire({
          text: `Would you like to update [Device ${item.sid}]'s name to [${item.name}]?`,
          confirmButtonText: "Update"
        });

        if( confirm ) {
          this.$debug('partialUpdate', item.name)
          var formData = new FormData();
          formData.append("name", item.name ? item.name : '' );
          this.$debug("partialUpdate", formData)

          await tokenAxios(this.user_info.access_token).patch(
            process.env.VUE_APP_API_SERVER + `/farm/devices/${item.id}/`,
            formData
          );

          this.$root.vtoast.show({message: `Device ${item.sid}'s name is updated`})

          // set Submitted
          this.status = 0;
        }
      } catch (e) {
        this.$debug(e);
        window.alert("Error! Please try again.");
      }
    },
  }
}
</script>
<style scoped>
.newdevice-container .v-data-table {
  border: 5px solid #4ecfbb;
  border-radius: 15px;
  /* background: #d4f0f0!important; */
}
.newdevice-container {
  /* max-height: 500px;
  height: 500px; */
  margin-right: 20px;
  margin-left: 20px;
}
.newdevice-container th {
  background: #4ecfbb!important;
}
.newdevice-container button {
  background-color: #4ecfbb!important;
  color: #fff!important;
}

.device-sid {
  padding: 10px 10px;
  border-radius: 20px;
}
.device-del {
  max-width: 100px;
}
.devicename, .devicename.v-text-field--solo .v-input__control {
  max-width: 320px;
  min-height: 30px;
  border-radius: 17px;  
}
.input-yellow .v-input__control .v-input__slot {
  box-shadow:  0 0 0;
  background: red;
}
.name-exist {
  color: red;
}
.nodata {
  height: 300px!important;
}
</style>
<style>
.add-device .v-input__slot {
  box-shadow:  0 0 0;
  border: 2px solid #4ecfbb!important;
  background-color: #79eeee2b!important;
}
.newdevice-container td {
  height: 40px!important;
  background-color: #79eeee2b!important;
}
</style>