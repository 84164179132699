<template>
<div>
  <!-- Mobile -->
  <template v-if="isMobile">
    <v-list-item-group color="primary " dense>
      <v-list-item key="status-3" v-if="isMinnowtech" to="/ponds" class="clickable justify-end back-mobile">
        <v-icon
          small
          color="blue"
        >
          mdi-arrow-left-bold-box
        </v-icon>
          Back
      </v-list-item>
      <v-list-item key="status-1" to="/farmersamples" :class="'clickable ' + activeMenu('/farmersamples')">
        <v-icon
          small
          color="red lighten-3"
        >
          mdi-chart-line
        </v-icon>
        Result ({{pond && pond.results_count}})
      </v-list-item>
      <v-list-item key="status-1" to="/farmer_report_new" :class="'clickable ' + activeMenu('/farmer_report_new')">
        <v-icon
          small
          color="red lighten-3"
        >
          mdi-chart-line
        </v-icon>
        New Result ({{pond && pond.results_new_count}})
      </v-list-item>
      <v-list-item key="status-2" to="/samples" :class="'clickable ' + activeMenu('/samples')">
        <v-icon
          small
          color="orange lighten-3"
        >
          mdi-database
        </v-icon>
        Samples ({{pond && pond.samples_count}})
      </v-list-item>
      <v-list-item key="status-3" v-if="isMinnowtech" to="/devices" :class="'clickable ' + activeMenu('/devices')">
        <v-icon
          small
          color="blue"
        >
          mdi-radar
        </v-icon>
          Devices ({{pond && pond.device.length}})
      </v-list-item>
      <v-list-item key="status-4" v-if="isMinnowtech" :to="'/updatepond/'+pond.id" :class="'clickable pa-0 mb-2'">
          <v-btn color="accent" @click="ChangeToAddPond()" width="100%">
            Update Pond
          </v-btn>
      </v-list-item>
    </v-list-item-group>
  </template>
  <!-- Desktop -->
  <template v-else>
    <div class="pb-4" style="border-bottom: 1px solid #cc">
      <router-link to="/farmersamples" :class="'menu clickable ' + activeMenu('/farmersamples')">
        <v-icon
          small
          color="red lighten-3"
        >
          mdi-chart-line
        </v-icon>
        Result 
        <v-avatar
          :color="activeColor('/farmersamples')"
          size="40"
        >
          <span class="white--text text-body-2">{{pond && pond.results_count}}</span>
        </v-avatar>        
      </router-link>
      <router-link v-if="isMinnowtech && pond.results_new_count" to="/farmer_report_new" :class="'menu clickable ' + activeMenu('/farmer_report_new')">
        <v-icon
          small
          color="red lighten-3"
        >
          mdi-chart-line
        </v-icon>
        New Result 
        <v-avatar
          :color="activeColor('/farmersamples')"
          size="40"
        >
          <span class="white--text text-body-2">{{pond && pond.results_new_count}}</span>
        </v-avatar>        
      </router-link>
      <router-link to="/samples" :class="'menu clickable ' + activeMenu('/samples')">
        <v-icon
          small
          color="orange lighten-3"
        >
          mdi-database
        </v-icon>
        Samples 
        <v-avatar
          :color="activeColor('/samples')"
          size="40"
        >
          <span class="white--text text-body-2">{{pond && pond.samples_count}}</span>
        </v-avatar>
      </router-link>
      <router-link v-if="isMinnowtech" to="/devices" :class="'menu clickable ' + activeMenu('/devices')">
        <v-icon
          small
          color="blue"
        >
          mdi-radar
        </v-icon>
        Devices 
        <v-avatar
          :color="activeColor('/devices')"
          size="40"
        >
          <span class="white--text text-body-2">{{pond && pond.device.length}}</span>
        </v-avatar>
      </router-link>
      <router-link v-if="isMinnowtech" to="/ponds" :class="'clickable pl-4'">
        <v-icon
          color="accent"
        >
          mdi-arrow-left-bold-box
        </v-icon>
        Back
      </router-link>
      <router-link v-if="isMinnowtech" :to="(pond ? '/updatepond/'+pond.id : '')" :class="'clickable float-right'">
        <v-btn small color="accent" @click="ChangeToAddPond()">
          Update Pond
        </v-btn>
      </router-link>
    </div>
  </template>
</div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: 'pond-menu',
    props: [
    ],
    computed: {
      ...mapState(["isMobile", "user_info", "isMinnowtech", "pond"]),
    },
    data () {
      return {
        clicked: false,
      }
    },
    methods: {
      activeMenu(menu) {
        this.$debug( 'this.$route.path', this.$route.path )
        return (this.$route.path === menu) ? 'menu_active' : ''
      },
      activeColor(menu) {
        this.$debug( 'this.$route.path', this.$route.path )
        return (this.$route.path === menu) ? 'secondary' : 'gray darken-1'
      },
    }
}
</script>

<style scoped>
  .menu {
    background: rgb(161 161 161);
    color: white!important;
    margin-right: 1px;
    padding: 5px 40px;
    border-radius: 10px 10px 0 0;
  }
  .menu_active {
    background: #46BFC6;
    color: white!important;
    border-radius: 20px;
  }
  .refreshbtn {
    display: flex;
    color: #46BFC6;
    text-decoration: none;
    align-items: center;
    margin-right: 15px;
  }

  .refreshing {
    color:rgb(209, 15, 54);
  }
  .v-avatar {
    margin-top: -2px;
  }
  .float-right {
    float: right;    
  }
  .back-mobile {
    min-height: 20px;
    background-color: #ffff0059;
    /* border-radius: 10px; */
    margin-top: -8px;
  }
</style>
<style>
.refresh-text .el-loading-spinner .el-loading-text {
  color: white;
}
</style>

