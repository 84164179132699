<template>
<v-row justify="end" class="ml-0" style="flex: 0 0 60px; align-items: center">
    <v-col :cols="isMobile ? 12 : 10">
        <h2 :class="'font-weight-bold' + (isMobile ? ' text-h6' : '')">
            {{getPond}} 
        </h2>
    </v-col>
    <v-col :cols="isMobile ? 0 : 2" class="latest custom-header">
      <v-icon
        small
        color="white"
        style="margin-right: 10px;"
      >
        mdi-calendar
      </v-icon>
      <span style="color: white">
        {{latest_sample_date}}
      </span>
    </v-col>
</v-row>
</template>

<script>
import { mapState } from "vuex";
import moment from 'moment'
import tokenAxios from "@/components/tokenAxios";

export default {
  name: 'CurrentLevel',
  props: [
  ],
  computed: {
    ...mapState(["isMobile", "user_info", "pond"]), // get user_info and pond from store
    // get Pond's name with WOC
    getPond() {
      if(!this.pond) return "";

      const diff = (this.pond.completed ? moment(this.pond.completed) : moment()).diff(moment(this.pond.date), 'days');
      const woc = (this.pond.completed ? moment(this.pond.completed) : moment()).diff(moment(this.pond.date), 'weeks');

      return `Pond ${this.pond.pond_id} - Stocking Date: ${this.$options.filters.formatDate(this.pond.date)} - ${diff} DOC (${woc} WOC)`
    },
  },
  data: () => ({
    API_SERVER: process.env.VUE_APP_API_SERVER,
    latest_sample_date: '',
  }),
  created() {
    this.$debug("created", this.pond)
    this.latestSampleDate();
  },
  methods: {
    // Get Latest Sample Date
    async latestSampleDate() {
      try {
          this.$debug( "API_SERVER", this.API_SERVER, "this.pond", this.pond )
          // this.$store.commit("loadingStatus", 1);
          const response = await tokenAxios(this.user_info.access_token).get(
            this.API_SERVER + `/farm/lastsample/?format=json&pond=${this.pond.id}`
          );
          this.$debug("get the latest sample date", response);
          if (response.status === 200) {
            if (response.data) {
              this.latest_sample_date = this.$options.filters.formatDate(response.data);
            } else {
              this.latest_sample_date = 'No Sample'
            }
          } else {
            this.$debug("error", response);
          }
      } catch (e) {
          this.$debug("error", e);
      } finally {
          // this.$store.commit("loadingStatus", 0);
      }
    },
  },
}
</script>
<style>
.latest {
  font-size: 14px;
  height: 25px;
  line-height: 25px;
  background: #0f326b;
  color: #fff;
  padding: 0px!important;
  text-align: center;
}
.custom-header {
  background-color: #46BFC6; 
}
</style>
